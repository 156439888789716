<template>
  <popup-model class="sightseeing_module" @close-model="$emit('onClose')" :closeBtn="false" :openModel="isNew">
    <div slot="content">
      <div class="model_title">{{ isUpdate ? "Update" : "Create" }} Sightseeing / Transfers</div>
      <hr class="my-2" />
      <div class="form_container">
        <div class="form-body">
          <div class="row gutter14">
            <div class="col-6">
              <div class="form-group mb-2" :class="{ invalid: $v.formData.service.$error }">
                <label class="mb-1 required">Service</label>
                <select @blur.prevent="$v.formData.service.$touch" :disabled="isUpdate" v-model.trim="formData.service" class="form-control">
                  <option value="">Choose</option>
                  <option value="sightseeing">Sightseeing</option>
                  <option value="transfers">Transfers</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-2" :class="{ invalid: $v.formData.seat_type.$error }">
                <label class="mb-1 required">Seat Type</label>
                <select @blur.prevent="$v.formData.seat_type.$touch" v-model="formData.seat_type" class="form-control">
                  <option value="">Choose</option>
                  <option value="ticket">Ticket Only</option>
                  <option value="seat_in_coach">Seat In Coach</option>
                  <option value="private">Private</option>
                  <option value="value_packs">Value Packs</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-2" :class="{ invalid: $v.formData.name.$error }">
                <label class="mb-1 required">Name</label>
                <input @blur.prevent="$v.formData.name.$touch" placeholder="Enter Name" v-model.trim="formData.name" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-2" :class="{ invalid: $v.formData.city.$error }">
                <label class="mb-1 required">City</label>
                <destSelector v-if="!isUpdate" :selectInput="true" @onSelectItem="onSelected" :selectedData="formData.city" :asyncData="getCityListByName" placeholder="Enter Name" />
                <span v-else class="form-control disabled">{{ formItem.city ? formItem.city.name : "" }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group mb-2">
                <label class="mb-1">Image Url</label>
                <input v-model.trim="formData.image_url" type="text" class="form-control" placeholder="Enter image url" />
              </div>
            </div>
            <div class="col-6">
              <div class="row no-gutters">
                <div class="col-md-6">
                  <div class="form-group mr-2">
                    <label class="mb-1">Timing</label>
                    <select v-model="formData.timing" class="form-control mr-2">
                      <option value="">Choose</option>
                      <option value="day">All Day</option>
                      <option value="morning">Morning</option>
                      <option value="afternoon">Afternoon</option>
                      <option value="evening">Evening</option>
                      <option value="night">Night</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mr-2">
                    <label class="mb-1">Hours</label>
                    <input v-model.trim="hours" type="text" v-input-type="'number'" class="form-control mr-2" placeholder="Hours" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="mb-1">Minutes</label>
                    <input v-model.trim="minutes" type="text" v-input-type="'number'" class="form-control mr-2" placeholder="Minutes" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <label class="mb-1">Description</label>
                <vue-editor class="sightseeing_description" :editor-toolbar="toolbarSettings" v-model.trim="formData.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_container">
        <label class="title">Pricing</label>
        <div class="form-body">
          <div v-if="formData.service == 'transfers'" class="row no-gutters">
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label class="d-flex align-items-center" style="font-weight: 600">
                  Price <span v-if="formData.seat_type == 'seat_in_coach'" class="fnt-11 clr-red font-bold ml-1">( The amount should be per person )</span>
                </label>
                <div class="mr-2">
                  <input v-input-type="'number'" type="text" v-model.trim="formData.pricing.adult" placeholder="Price" class="form-control form-control-sm mt-2" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row no-gutters">
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label class="d-flex">Adult</label>
                <div class="mr-2">
                  <input v-input-type="'number'" type="text" v-model.trim="formData.pricing.adult" placeholder="Price" class="form-control form-control-sm mt-2" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-0">
                <label class="d-flex align-items-center">Child <input type="checkbox" v-model.trim="formData.pricing.is_child" class="ml-2" /></label>
                <div class="mr-2">
                  <input
                    v-input-type="'number'"
                    type="text"
                    v-model.trim="formData.pricing.child"
                    placeholder="Price"
                    :disabled="!formData.pricing.is_child"
                    class="form-control form-control-sm mt-2"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-0">
                <label class="d-flex align-items-center">Infant <input type="checkbox" v-model.trim="formData.pricing.is_infant" class="ml-2" /></label>
                <div>
                  <input
                    v-input-type="'number'"
                    type="text"
                    v-model.trim="formData.pricing.infant"
                    placeholder="Price"
                    :disabled="!formData.pricing.is_infant"
                    class="form-control form-control-sm mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_container">
        <div class="form-body">
          <div class="d-flex justify-content-end mt-2">
            <button @click.prevent="handleSubmitSightForm" href="javascript:void(0)" class="btn btn-info mr-2">{{ isUpdate ? "Update" : "Save" }}</button>
            <button @click.prevent="$emit('onClose')" href="javascript:void(0)" class="btn btn-danger">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </popup-model>
</template>

<script>
import destSelector from "../create/components/basic/dest-selector.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "InsertSightseeing",
  components: {
    destSelector
  },
  props: {
    isNew: { type: Boolean, default: false },
    isUpdate: { type: Boolean, default: false },
    formItem: { type: Object }
  },
  data() {
    return {
      hours: "",
      minutes: "",
      formData: {
        name: "",
        city: {},
        service: "",
        seat_type: "",
        description: "",
        image_url: "",
        duration: "",
        timing: "",
        pricing: {
          adult: "",
          is_child: false,
          child: "",
          is_infant: false,
          infant: ""
        }
      }
    };
  },
  validations: {
    formData: {
      service: { required },
      seat_type: { required },
      name: { required },
      city: { required }
    }
  },
  watch: {
    formItem(value) {
      if (this._.isEmpty(value)) {
        this.formData = {
          name: "",
          city: {},
          description: "",
          image_url: "",
          service: "",
          seat_type: "",
          duration: "",
          timing: "",
          pricing: { adult: "", is_child: false, child: "", is_infant: false, infant: "" }
        };
      } else {
        this.formData = value;
        if (value.duration) {
          const _hour = value.duration?.split(" hr")[0];
          const _minutes = +_hour ? value.duration.split(" hr")[1]?.split(" mins")[0] : value.duration?.split(" mins")[0];
          if (+_hour) this.hours = +_hour;
          if (+_minutes) this.minutes = +_minutes;
        }
      }
      this.$v.$reset();
    },
    hours(value) {
      let _text = value > 0 ? value + " hr" : "";
      if (this.minutes > 0) _text += " " + this.minutes + " mins";
      this.formData.duration = _text;
    },
    minutes(value) {
      let _text = value > 0 ? value + " mins" : "";
      if (this.hours > 0) _text = this.hours + " hr " + _text;
      this.formData.duration = _text;
    },
    isNew(value) {
      if (!value) {
        this.formData = {
          name: "",
          city: {},
          description: "",
          image_url: "",
          duration: "",
          service: "",
          seat_type: "",
          timing: "",
          pricing: { adult: "", is_child: false, child: "", is_infant: false, infant: "" }
        };
        this.$emit("onClose");
        this.$v.$reset();
      }
    },
    "formData.pricing.is_child": function (value) {
      if (!value) this.formData.pricing.child = "";
    },
    "formData.pricing.is_infant": function (value) {
      if (!value) this.formData.pricing.infant = "";
    }
  },
  methods: {
    ...mapActions(["createPackageSightseeing", "getCityListByName"]),
    onSelected(value) {
      this.formData.city = { ...value, name: value.destinationName };
    },
    handleSubmitSightForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createPackageSightseeing({ ...this.formData, city: this.formData.city.id }).then((data) => {
          this.$emit("onClose");
        });
      }
    }
  }
};
</script>

<style scoped>
.sightseeing_module >>> .popup_body {
  border-radius: 0;
  max-height: fit-content;
  width: 650px;
  padding: 0;
}

.sightseeing_module >>> .model_title {
  font-size: 16px;
  font-weight: 600;
  padding: 4px 10px 0px 10px;
}

.sightseeing_module >>> .form_container {
  padding: 0px 10px 10px 10px;
}

.sightseeing_module >>> .form_container:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
  margin-top: 5px;
  padding-bottom: 10px;
}

.sightseeing_module >>> .form_container > .title {
  font-weight: 600;
  font-size: 14px;
}

.sightseeing_module >>> .form_container button {
  width: 75px;
  border-radius: 0px;
  font-size: 12px;
  height: 26px;
  line-height: 5px;
}

.form-control.disabled {
  background-color: #e6e6e6;
}

.sightseeing_description >>> .ql-editor {
  min-height: 140px;
}
</style>
